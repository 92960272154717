// Angular Imports
// =========================================================
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { CommonModule } from '@angular/common';
// Material Components (UI) Imports
// =========================================================
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
// AG Grid
// =========================================================
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-community';
import 'ag-grid-enterprise';
// Prime NG (UI) Imports
// =========================================================
import { ChartModule } from 'primeng/chart';
import { DragDropModule } from 'primeng/dragdrop';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService, DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { TreeSelectModule } from 'primeng/treeselect';
import { TreeModule } from 'primeng/tree';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import {AutoCompleteModule} from 'primeng/autocomplete';
// Routing
// =========================================================
import { AppRoutingModule } from './app-routing.module';
// Services Imports
// =========================================================
import { CreateTestScenariosService } from './core/services/db/create-test-scenarios.service';
import { DefineSizeOfferingsService } from './core/services/db/define-size-offerings.service';
import { GlobalServices } from './core/services/db/global-services.service';
import { SetCartonAndBuyParamsService } from './core/services/db/set-carton-and-buy-params.service';

// Component Imports
// =========================================================
import { AppComponent } from './app.component';
import { DashboardPageComponent } from './components/01_pages/02_dashboard/dashboard-page.component';
import { DashboardTileComponent } from './components/01_pages/02_dashboard/dashboard-tile/dashboard-tile.component';
import { ReviewOrdersComponent } from './components/01_pages/03_review-orders/review-orders.component';
import { OrderManagementGridComponent } from './components/01_pages/03_review-orders/ro-ag-grid/ro-ag-grid.component';
import { SourceDestCartonTooltip } from './components/01_pages/03_review-orders/ro-ag-grid/source-dest-carton-tooltip/source-dest-carton-tooltip.component';
import { DefineSizeOfferingsComponent } from './components/01_pages/04_define-size-offerings/define-size-offerings.component';
import { SetCartonAndBuyParamsComponent } from './components/01_pages/05_set-carton-and-buy-params/set-carton-and-buy-params.component';
import { TuneMLComponent } from './components/01_pages/06_tune-ml/tune-ml.component';
import { ButtonCellRenderer } from './components/02_ag-grid-settings/01_ag-custom-components/button-cell-renderer/button-renderer';
import { StatusPanelRenderer } from './components/02_ag-grid-settings/01_ag-custom-components/status-panel-renderer/status-panel-renderer';
import { ModalHeaderComponent } from './components/03_modals/01_modal-header/modal-header.component';
import {
  AddSizeRecommendationModalComponent
} from './components/03_modals/define-size-offerings/add-new-size/define-size-offerings-add-size.component';
import {
  AddNewSizeModalComponent
} from './components/03_modals/define-size-offerings/add-size-recommendation/add-size-recommendation.component';
import { SaveSizeOfferingComponent } from './components/03_modals/define-size-offerings/save-size-offerings/save-size-offerings.component';
import { MismatchChartComponent } from './components/03_modals/review-orders/mismatch-chart/mismatch-chart.component';
import { PackSummaryConfigComponent } from './components/03_modals/review-orders/pack-summary-config/pack-summary-config.component';
import { UserInputConfigComponent } from './components/03_modals/review-orders/user-input-config/user-input-config.component';
import { EditStoreDistroModalComponent } from './components/03_modals/set-carton-and-buy-params/edit-store-distro/edit-store-distro-modal.component';
import { RunTestScenariosComponent } from './components/03_modals/set-carton-and-buy-params/run-test-scenarios/run-test-scenarios.component';
import { VendorPacksModalComponent } from './components/03_modals/set-carton-and-buy-params/vendor-packs/vendor-packs-modal.component';
import {
  FilterSidePanelTriggerComponent
} from './components/04_filters/filter-side-panel-trigger/filter-side-panel-trigger.component';
import { FiltersPanelComponent } from './components/04_filters/filters-panel/filters-panel.component';
import { TreeFilterComponent } from './components/04_filters/tree-filter/tree-filter.component';
// import { IconButtonCellRenderer } from './components/02_ag-grid-settings/01_ag-custom-components/icon-button-cell-renderer/icon-button-renderer';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { LoginComponent } from './components/01_pages/01_login/login.component';
import { DefineSizeOfferingsDetailGrid } from './components/01_pages/04_define-size-offerings/dso-detail-grid/define-size-offerings-detail-grid.component';
import { SetCartonAndBuyParamsDetailGrid } from './components/01_pages/05_set-carton-and-buy-params/scabp-detail-grid/set-carton-and-buy-params-detail-grid.component';
import { CreateTestScenariosComponent } from "./components/01_pages/07_create-test-scenarios/create-test-scenarios.component";
import { CreateTestScenarioDetailGrid } from './components/01_pages/07_create-test-scenarios/cts-detail-grid/create-test-scenarios-detail-grid.component';
import { GridCustomTooltipComponent } from './components/02_ag-grid-settings/01_ag-custom-components//grid-custom-tooltip/grid-custom-tooltip.component';
import { ManageDistroChanges } from './components/02_ag-grid-settings/01_ag-custom-components/custom-tool-panels/set-carton-and-buy-params/manage-distro-changes';
import { DropdownCellRendererComponent } from './components/02_ag-grid-settings/01_ag-custom-components/dropdown-cell-renderer/dropdown-cell-renderer.component';
import { GridLoadingOverlay } from './components/02_ag-grid-settings/01_ag-custom-components/grid-loading-overlay/grid-loading-overlay';
import { RowLoadingCellRenderer } from './components/02_ag-grid-settings/01_ag-custom-components/row-loading-renderer/row-loading-renderer';
import { ErrModalComponent } from './components/03_modals/03_error-modal/err-modal.component';
import { TestScenarioCreateModalComponent } from "./components/03_modals/create-test-scenarios/test-scenario-create-modal.component";
import { HierarchySelectComponent } from './components/03_modals/review-orders/hierarchy-level-select/hierarchy-level-select.component';
import { StoreTotalsChartsComponent } from './components/03_modals/review-orders/store-totals-charts/store-totals-charts.component';
import { RunTestScenariosDetailGrid } from './components/03_modals/set-carton-and-buy-params/run-test-scenarios/rts-detail-grid/run-test-scenarios-detail-grid.component';
import { VendorPackModalDetailGrid } from './components/03_modals/set-carton-and-buy-params/vendor-packs/vp-detail-grid/vendor-packs-modal-detail-grid.component';
import { PrimeNGTreeFilter } from './components/04_filters/primeng-tree-filter/primeng-tree-filter.component';
import { LoaderComponent } from './components/05_shared-components/loader/loader.component';
import { NavBarComponent } from './components/05_shared-components/navbar/nav-bar.component';
import { LoadingModalComponent } from './components/03_modals/04_loading-modal/loading-modal.component';
import { AutoSelectionCellRenderer } from './components/02_ag-grid-settings/01_ag-custom-components/auto-selection-cell-renderer/auto-selection-cell-renderer';
import { AuthGuard } from './core/services/auth/auth-guard';
import { ManageSizeOfferingComponent } from './components/03_modals/define-size-offerings/manage-size-offerings/manage-size-offerings.component';
import { DeleteSizeRangesComponent } from './components/03_modals/define-size-offerings/delete-size-ranges/delete-size-ranges.component';
import { UploadNewSizeOfferingComponent } from './components/03_modals/define-size-offerings/manage-size-offerings/upload-new-size-offerings/upload-new-size-offerings.component';
import { ReviewRequestsComponent } from './components/03_modals/define-size-offerings/manage-size-offerings/review-requests/review-requests.component';
import { ModalFooterComponent } from './components/03_modals/02_modal-footer/modal-footer.component';
import { AutoInputDelimiterCellEditor } from './components/02_ag-grid-settings/01_ag-custom-components/auto-input-delimiter-cell-renderer/auto-input-delimiter-cell-editor';
import { EditableCellIconRenderer } from './components/02_ag-grid-settings/01_ag-custom-components/editable-cell-icon-renderer/editable-cell-icon-renderer';
import { ChatWindowComponent } from './components/06_chat/chat-window/chat-window.component';
import { ChatMessageComponent } from './components/06_chat/chat-message/chat-message.component';
import { InputTextModule } from 'primeng/inputtext';
import { ChatWrapperComponent } from './components/06_chat/chat-wrapper.component';
import { DialogModule } from 'primeng/dialog';
import { ChatService } from './core/services/chat.service';



@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    LoaderComponent,
    ReviewOrdersComponent,
    OrderManagementGridComponent,
    UserInputConfigComponent,
    PackSummaryConfigComponent,
    VendorPacksModalComponent,
    EditStoreDistroModalComponent,
    ButtonCellRenderer,
    // IconButtonCellRenderer,
    DropdownCellRendererComponent,
    ModalHeaderComponent,
    ModalFooterComponent,
    MismatchChartComponent,
    GridCustomTooltipComponent,
    DefineSizeOfferingsComponent,
    AddSizeRecommendationModalComponent,
    AddNewSizeModalComponent,
    SaveSizeOfferingComponent,
    FiltersPanelComponent,
    FilterSidePanelTriggerComponent,
    SetCartonAndBuyParamsComponent,
    RunTestScenariosComponent,
    DashboardPageComponent,
    TreeFilterComponent,
    TuneMLComponent,
    DashboardTileComponent,
    CreateTestScenariosComponent,
    TestScenarioCreateModalComponent,
    TuneMLComponent,
    StoreTotalsChartsComponent,
    RowLoadingCellRenderer,
    StatusPanelRenderer,
    ErrModalComponent,
    LoadingModalComponent,
    ManageDistroChanges,
    GridLoadingOverlay,
    CreateTestScenarioDetailGrid,
    SetCartonAndBuyParamsDetailGrid,
    RunTestScenariosDetailGrid,
    DefineSizeOfferingsDetailGrid,
    VendorPackModalDetailGrid,
    LoginComponent,
    HierarchySelectComponent,
    SourceDestCartonTooltip,
    PrimeNGTreeFilter,
    UploadNewSizeOfferingComponent,
    AutoSelectionCellRenderer,
    ManageSizeOfferingComponent,
    ReviewRequestsComponent,
    AutoInputDelimiterCellEditor,
    EditableCellIconRenderer,
    PrimeNGTreeFilter, 
    UploadNewSizeOfferingComponent,
    DeleteSizeRangesComponent,
    AutoSelectionCellRenderer, 
    ManageSizeOfferingComponent, 
    ReviewRequestsComponent,
    ChatWindowComponent,
    ChatMessageComponent,
    ChatWrapperComponent
  ],
  imports: [
    NgIdleKeepaliveModule.forRoot(),
    HttpClientModule,
    BrowserModule,
    AmplifyAuthenticatorModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    AgGridModule,
    //   .withComponents([
    //   ButtonCellRenderer,
    //   StatusPanelRenderer,
    //   // IconButtonCellRenderer,
    //   DropdownCellRendererComponent,
    //   GridCustomTooltipComponent,
    //   CreateTestScenarioDetailGrid,
    //   SetCartonAndBuyParamsDetailGrid,
    //   RunTestScenariosDetailGrid,
    //   DefineSizeOfferingsDetailGrid,
    //   VendorPackModalDetailGrid,
    //   RowLoadingCellRenderer,
    //   ManageDistroChanges,
    //   GridLoadingOverlay,
    //   SourceDestCartonTooltip
    // ]),

    MatCardModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatFormFieldModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatGridListModule,
    MatTableModule,
    MatSidenavModule,

    ProgressSpinnerModule,
    FileUploadModule,
    TableModule,
    DropdownModule,
    DynamicDialogModule,
    DragDropModule,
    TriStateCheckboxModule,
    ChartModule,
    MatTreeModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDividerModule,
    TreeModule,
    TreeSelectModule,
    ToastModule,
    ButtonModule,
    AutoCompleteModule,
    InputTextModule,
    DialogModule
  ],
  entryComponents: [
    AddSizeRecommendationModalComponent,
    TestScenarioCreateModalComponent,
    VendorPacksModalComponent,
    EditStoreDistroModalComponent,
    StoreTotalsChartsComponent,
    ErrModalComponent,
    LoadingModalComponent,
    HierarchySelectComponent,
    ChatWindowComponent
  ]
  ,
  providers: [
    DefineSizeOfferingsService,
    CreateTestScenariosService,
    SetCartonAndBuyParamsService,
    ChatService,
    GlobalServices,
    DialogService,
    DynamicDialogConfig,
    DynamicDialogRef,
    AuthGuard,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
